import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useSharedState from './hooks/useSharedState';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainLayout from "./layout/MainLayout";
import Home from "./pages/home/Home";
import Collection from "./pages/collection/Collection";

import OrdinalsView from "./pages/ordinals-view/OrdinalsView";
import OrdinalDetail from "./pages/ordinal_detail/OrdinalDetail";
import Whitepaper from "./pages/whitepaper/Whitepaper";
import Activity from "./pages/activity/Activity";

function App() {
  const [ordinal, setOrdinal] = useState(null);
  const [collection, setCollection] = useState(null);

  const {isNight}=useSharedState();
  
  const selectCollection = (collection) => {
    setCollection(collection);
  }

  const buyOrdinal = (ordinal) => {
    setOrdinal(ordinal);
  }

  return (
    <div className={"App "+(!isNight?"text-black bg-white":"text-white bg-ordinsea-panelDark")}>
      <Router>
        <ToastContainer pauseOnFocusLoss={false} />
        <MainLayout>
          <Routes>
            <Route exact path="/" element={<Home selectCollection={selectCollection} />} />
            <Route path="collection" element={<Collection selectCollection={selectCollection} />} />
            <Route exact path="activity" element={<Activity />} />
            <Route path="ordinals" element={<OrdinalsView collection={collection} buyOrdinal={buyOrdinal} />} />
            <Route path="ordinal-detail" element={<OrdinalDetail ordinal={ordinal} />} />
            <Route path="whitepaper" element={<Whitepaper />} />
          </Routes>
        </MainLayout>
      </Router>
    </div>
  );
}

export default App;
