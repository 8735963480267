export const menuItems = [
  // {
  //   label: "Home",
  //   link: "home",
  // },
  {
    label: "Collection",
    link: "collection",
  },
  {
    label: "Activity",
    link: "activity",
  },
  {
    label: "White Paper",
    link: "whitepaper",
  },
];
