import React, { useEffect, useState } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import ImagesDatas from "../config/image";
import useSharedState from './../hooks/useSharedState';
import { NoEthereumProviderError } from "@web3-react/injected-connector";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Typography,
  MenuItem,
} from "@material-tailwind/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import * as connect from '@stacks/connect';
import { StacksMainnet, StacksTestnet } from '@stacks/network';

const BTC_NETWORK = 'testnet';

const USER_NOT_SIGNED_IN = 0;
const USER_SIGNED_IN = 1;
const USER_SIGN_IN_PENDING = 2;

export const Connect = function (props) {
  const { className } = props;

  const {connectIcon, connectIcon_b, favicon, HiroWallet, XverseWallet, ic_metamask, ic_walletConnect}=ImagesDatas();
  const {userWalletData, isNight, setUserWalletData}=useSharedState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const appConfig = new connect.AppConfig(['publish_data']);
  const userSession = new connect.UserSession({ appConfig });

  const [userState, setUserState] = useState(USER_NOT_SIGNED_IN);

  const authenticate = () => {
    connect.showConnect({
      userSession,
      network: BTC_NETWORK === 'mainnet' ? StacksMainnet : StacksTestnet,
      appDetails: {
        name: "Ordinsea",
        icon: favicon,
        // icon: `${window.location.href}/icon.png`
      },
      onFinish: () => {
        setUserWalletData(userSession.loadUserData());
        setUserState(USER_SIGNED_IN);
      },
    })
  }

  const signOut = () => {
    console.log("signOut = ");
    userSession.signUserOut();
    setUserWalletData(null);
    setUserState(USER_NOT_SIGNED_IN);
    // window.location.reload();
  }

  useEffect(() => {
    if (userSession.isUserSignedIn()) {
      setUserWalletData(userSession.loadUserData());
      setUserState(USER_SIGNED_IN)
    } else if (userSession.isSignInPending()) {
      setUserWalletData(null);
      setUserState(USER_SIGN_IN_PENDING);
      userSession.handlePendingSignIn()
      .then((userData) => {
        setUserWalletData(userData);
        setUserState(USER_SIGNED_IN)
      });
    } else {
      setUserWalletData(null);
      setUserState(USER_NOT_SIGNED_IN);
    }
  }, [setUserWalletData]);

  return (
    <React.Fragment>
      <>
        {userState === USER_SIGNED_IN && (
          <button
            type="button"
            className={
              className !== undefined
                ? className
                : "btn btn-basic ml-lg-auto"
            }
            onClick={() => {signOut()}}
          >
            <div className="flex rounded-full border border-ordinsea-fontYellow py-3 px-5 cursor-pointer">
              <img src={(!isNight?connectIcon_b:connectIcon)} className="max-h-4.5 pr-2 my-auto" alt="connectIcon" />
              {userWalletData !==null &&
                <Typography variant="paragraph" className=" ">{(userWalletData['profile']['btcAddress']['p2wpkh'].mainnet).slice(0,7)}...{(userWalletData['profile']['btcAddress']['p2wpkh'].mainnet).slice(38)}</Typography>
              }
            </div>
          </button>
        )}
        {userState !== USER_SIGNED_IN && (
            <button
              type="button"
              className={
                className !== undefined
                  ? className
                  : "btn btn-basic ml-lg-auto"
              }
              onClick={() => {
                setOpen(!open);
              }}
            >
              {userState === USER_SIGN_IN_PENDING && <>
                <div className="flex rounded-full border border-ordinsea-fontYellow hover:bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight py-3 px-5 cursor-pointer">
                  <img src={(!isNight?connectIcon_b:connectIcon)} className="max-h-4.5 pr-2 my-auto" alt="connectIcon" />
                  <Typography variant="paragraph" className=" ">Connecting...</Typography>
                </div>
              </>}
              {userState !== USER_SIGN_IN_PENDING && <>
                <div className="flex rounded-full border border-ordinsea-fontYellow hover:bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight py-3 px-5 cursor-pointer">
                  <img src={(!isNight?connectIcon_b:connectIcon)} className="max-h-4.5 pr-2 my-auto" alt="connectIcon" />
                  <Typography variant="paragraph" className=" ">Connect Wallet</Typography>
                </div>
              </>}
            </button>
          )}
      </>

      <Dialog size="xs" open={open} handler={handleOpen} className="min-w-[100%] max-w-[100%] lg:min-w-[20%] lg:max-w-[20%] h-1/2">
        <DialogHeader className="justify-between h-[20%]">
          <Typography variant="h5" color="blue-gray">
            Connect a Wallet
          </Typography>
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={handleOpen}
          >
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </DialogHeader>
        <DialogBody className="pr-2 h-[60%]">
          <div>
            <ul className="mt-1 -ml-2 flex flex-col gap-1">
              <MenuItem
                className="flex items-center gap-3"
                onClick={() => {
                  // activate(injected);
                  authenticate();
                  setOpen(false);
                }}
              >
                <img src={HiroWallet} alt="metamast" className="h-6 w-6" />
                <Typography color="blue-gray" variant="h6">
                  Hiro
                </Typography>
              </MenuItem>
              <MenuItem
                className="flex items-center gap-3"
                onClick={() => {
                  // activate(walletconnect);
                  // setOpen(false);
                }}
              >
                <img
                  src={XverseWallet}
                  alt="metamast"
                  className="h-6 w-6 rounded-md"
                />
                <Typography color="blue-gray" variant="h6">
                  Xverse
                </Typography>
              </MenuItem>
            </ul>
          </div>
        </DialogBody>
        <DialogFooter className="justify-between h-[20%]">
          <Typography variant="small" color="gray" className="font-normal">
            New to Ethereum wallets?
          </Typography>
          <Button variant="text" size="sm">
            Learn More
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};

export default Connect;
