import React from "react";
import { Typography } from "@material-tailwind/react";

export default function ActivityIcon(props) {
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    React.useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <div className="flex flex-row">
                <img className="w-9 my-auto" src={props.image} alt="toToken" />
                <div className="pl-3  ">
                    <Typography variant="paragraph" className="mt-1">{props.label}</Typography>
                    <Typography variant="small" className="mt-1">{props.id}</Typography>
                </div>
            </div>
        </React.Fragment>
    );    
}