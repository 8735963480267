import React from "react";
import { toast } from "react-toastify";
import useSharedState from '../../hooks/useSharedState';
import ImagesDatas from "../../config/image";
import { config } from '../../config/config';
import {getOrdinals, getOrdinalActivity} from "../../utils/api";

import {
    Typography,
    Select,
    Option, } from "@material-tailwind/react";

export default function OrdinalDetail(props) {
    const createdDateRef = React.useRef(null);

    const {
        instagramImage, 
        twitterImage, 
        discordImage, 
        bitcoinImage,
        cartImage,
        listImage,
        offersImage,
        transterImage, 
        heroImage, 
        spline1Image }=ImagesDatas();

    const {ordinalDetail, setOrdinalDetail, isNight, userWalletData}=useSharedState();
    const [active, setActive]=React.useState(1);

    const [unsignedBuyerPsbt, setUnsignedBuyerPsbt]=React.useState('');
    const [unsignedDummyUtxoPsbt, setUnsignedDummyUtxoPsbt]=React.useState('');

    const [ordinalActivity, setOrdinalActivity]=React.useState('');

    createdDateRef.current = new Date(ordinalDetail['inscription'].timestamp);

    const generateUnsignedBuyerPsbt = async () => {
        setUnsignedBuyerPsbt('');
        setUnsignedDummyUtxoPsbt('');
        let signAtIndex = [];

        const res = await fetch(`${config.baseUrl}/buying`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "ordinal_output": props.ordinal["inscription"].output,
                "ordinal_output_value": props.ordinal["inscription"].value,
                "ordinal_location": props.ordinal["inscription"].location,
                "tap_internal_key": props.ordinal.tap_internal_key,
                "seller_receive_address": props.ordinal.seller_receive_address,
                "price": props.ordinal.price,
                "maker_fee_bp": 1,
                "buyer_address": userWalletData['profile']['btcAddress']['p2wpkh'].mainnet,
                "buyer_receive_address": userWalletData['profile']['btcAddress']['p2tr'].mainnet,
                "buyer_public_key": userWalletData['profile']['btcPublicKey'].p2wpkh,
                "taker_fee_bp": 1,
                "fee_rate_tier": "halfHourFee",
            }),
        });

        const data = await res.json();
        if (data.status === 'success') {
            setUnsignedBuyerPsbt(data.listingState.buyer.unsignedBuyingPSBTBase64);
            setUnsignedDummyUtxoPsbt('');
            signAtIndex = data.signAtIndex;
        } else if (data.status === 'dummyUtxo') {
            setUnsignedBuyerPsbt('');
            setUnsignedDummyUtxoPsbt(data.psbtBase64);
            signAtIndex = data.signAtIndex;
        } else {
            toast.error('Buying error!');
            console.error(data);
        }
    }

    React.useEffect(() => {
        getOrdinalActivity(props.ordinal.inscription_id).then(({data})=> {
            console.log("getOrdinalActivity = ", data['data']);
            setOrdinalActivity(data['data']);
        });

        getOrdinals(props.ordinal.ordinal_id).then(({data})=> {
            console.log("ordinalDetail = ", data);
            
            if(data !== undefined)
                setOrdinalDetail(data);
            
            const created_date = new Date(data['inscription'].timestamp);
            createdDateRef.current = created_date;
        });
    },[props.ordinalId, setOrdinalDetail]);

    return <React.Fragment>
        <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
            <div className="absolute left-0 top-[15%] lg:top-[50%] z-10">
                <img src={spline1Image} alt="spline1Image" className="w-full"/>
            </div>
            <div className="absolute left-[50%] top-0 z-10">
                <img src={heroImage} alt="hero" className="w-full"/>
            </div>
            <div className="relative z-50">
                <div className="container p-3 mx-auto lg:px-16">
                    <div className="pt-48">
                        <div className="flex flex-col lg:flex-row gap-32 justify-between">
                            <div className="w-full">
                                <img src={"https://ordinals.com/content/"+ordinalDetail.inscription_id} alt="nft_image" className="w-full lg:w-full lg:h-128"/>
                                <div className="mt-16 z-50">
                                    <Typography variant="h4">Details</Typography>
                                    <div className="mt-3 flex flex-col lg:flex-row gap-3">
                                        <div className="bg-white/[0.1] px-4 py-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Inscription ID</Typography>
                                            <Typography variant="paragraph" className="">{(ordinalDetail.inscription_id).slice(0,5)}...{(ordinalDetail.inscription_id).slice(63)}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Inscription Number</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail['inscription'].number}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Owner</Typography>
                                            <Typography variant="paragraph" className="">{(ordinalDetail['inscription'].address).slice(0,5)}...{(ordinalDetail['inscription'].address).slice(59)}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Content</Typography>
                                            <a href="https://api.hiro.so/ordinals/v1/inscriptions/content"><Typography variant="paragraph" className="">Link</Typography></a>
                                        </div>
                                    </div>
                                    <div className="mt-3 flex flex-col lg:flex-row gap-3">
                                        <div className="bg-white/[0.1] px-4 py-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Content Type</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail.content_type}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Created</Typography>
                                            <Typography variant="paragraph" className="">{(createdDateRef.current).toLocaleString()}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Genesis Transaction</Typography>
                                            <Typography variant="paragraph" className="">{(ordinalDetail['inscription'].genesis_tx_id).slice(0,5)}...{(ordinalDetail['inscription'].genesis_tx_id).slice(61)}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Sat Rarity</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail['sat'].rarity}</Typography>
                                        </div>
                                    </div>
                                    <div className="mt-3 flex flex-col lg:flex-row gap-3">
                                        <div className="bg-white/[0.1] px-4 py-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Sat Number</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail['inscription'].sat_ordinal}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Sat Name</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail['sat'].name}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Location</Typography>
                                            <Typography variant="paragraph" className="">{(ordinalDetail['inscription'].location).slice(0,5)}...{(ordinalDetail['inscription'].location).slice(65)}</Typography>
                                        </div>
                                    </div>
                                    <div className="mt-3 flex flex-col lg:flex-row gap-3">
                                        <div className="bg-white/[0.1] px-4 py-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Location BlockHeight</Typography>
                                            <Typography variant="paragraph" className="">{ordinalDetail['inscription'].sat_coinbase_height}</Typography>
                                        </div>
                                        <div className="bg-white/[0.1] p-2 rounded-md">
                                            <Typography variant="small" className="mb-1">Output</Typography>
                                            <Typography variant="paragraph" className="">{(ordinalDetail['inscription'].output).slice(0,5)}...{(ordinalDetail['inscription'].output).slice(63)}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-3xl lg:text-4xl">#{ordinalDetail['inscription'].number} Inscription</p>
                                <p className="text-2xl">Top Offer</p>
                                <div className="flex mt-3">
                                    <img src={bitcoinImage} alt="bitcoin" className="w-11 h-9 my-auto pr-2"/>
                                    <Typography variant="lead">{ordinalDetail['inscription'].value}</Typography>
                                </div>
                                <div className="flex mt-10">
                                    {/* <button className="px-10 py-3 border border-ordinsea-fontYellow rounded-full hover:bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button"><Typography variant="lead">Make Offer</Typography></button> */}
                                    <button className="px-10 py-3 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={generateUnsignedBuyerPsbt}><Typography variant="lead">Buy Now</Typography></button>
                                </div>
                                <div className="  mt-10">
                                    <ul className="flex gap-5 pr-10 my-auto">
                                        <li className="cursor-pointer" onClick={() => {setActive(1)}}>
                                            <Typography as="p" variant="lead" className={(active===1?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Info</Typography>
                                        </li>
                                        <li className="cursor-pointer" onClick={() => {setActive(2)}}>
                                            <Typography as="p" variant="lead" className={(active===2?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Activity</Typography>
                                        </li>
                                    </ul>
                                    {active===1?(
                                        <div className="mt-5">
                                            <Typography variant="h3">Ey3k0n First Edition</Typography>
                                            <div className="mt-3">
                                                <Typography variant="paragraph">Ut quis turpis eget justo scelerisque tincidunt. Nulla facilisi.</Typography>
                                                <Typography variant="paragraph">Nunc sed mi a augue pretium feugiat. Integer sagittis aliquam</Typography>
                                                <Typography variant="paragraph">eros, id pharetra sapien ultrices vel. Morbi cursus dui odio, id</Typography>
                                                <Typography variant="paragraph">tempor ex imperdiet sed. Ut sodales ex ac ex sodales, ut mattis</Typography>
                                                <Typography variant="paragraph">lorem porttitor. Morbi quis sem tellus.</Typography>
                                            </div>
                                            <div className="flex gap-3 mt-3">
                                                <img src={instagramImage} alt="instagram" className="w-7 h-7"/>
                                                <img src={twitterImage} alt="twitter" className="w-7 h-7"/>
                                                <img src={discordImage} alt="discord" className="w-7 h-7"/>
                                            </div>
                                        </div>
                                    ):(
                                        <>
                                            <div className="mt-8">
                                                <Select label="All event" color="teal" className="bg-blue-200/[.05]">
                                                    <Option>Price Low to High</Option>
                                                    <Option>Price High to Low</Option>
                                                </Select>
                                            </div>
                                            <div className="  mt-10">
                                                <div className="py-4 px-2 flex flex-col lg:flex-row justify-between items-center border-y border-y-2 border-y-white/[.2] cursor-pointer hover:bg-[#232323]">
                                                    <div className="flex">
                                                        <img src={cartImage} alt="cart_image" className="h-6 mt-1 mr-3"/>
                                                        <div>
                                                            <Typography variant="lead">Sales</Typography>
                                                            <div className="flex mt-1">
                                                                <img src={bitcoinImage} alt="bitcoin" className="h-4 mr-2 my-auto"/>
                                                                <Typography variant="paragraph">0.23</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Typography variant="lead">yatseen.eth to steddy.eth</Typography>
                                                        <Typography variant="paragraph" className="text-center lg:text-right">5 Days ago</Typography>
                                                    </div>
                                                </div>
                                                <div className="py-4 px-2 flex flex-col lg:flex-row justify-between items-center border-b border-b-2 border-b-white/[.2] hover:bg-[#232323] cursor-pointer">
                                                    <div className="flex">
                                                        <img src={listImage} alt="list_image" className="h-6 mt-1 mr-3"/>
                                                        <div>
                                                            <Typography variant="lead">Listings</Typography>
                                                            <div className="flex mt-1">
                                                                <img src={bitcoinImage} alt="bitcoin" className="h-4 mr-2 my-auto"/>
                                                                <Typography variant="paragraph">0.23</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Typography variant="lead">yatseen.eth to steddy.eth</Typography>
                                                        <Typography variant="paragraph" className="text-center lg:text-right">5 Days ago</Typography>
                                                    </div>
                                                </div>
                                                <div className="py-4 px-2 flex flex-col lg:flex-row justify-between items-center border-b border-b-2 border-b-white/[.2] hover:bg-[#232323] cursor-pointer">
                                                    <div className="flex">
                                                        <img src={offersImage} alt="offer_image" className="h-6 mt-1 mr-3"/>
                                                        <div>
                                                            <Typography variant="lead">Offer</Typography>
                                                            <div className="flex mt-1">
                                                                <img src={bitcoinImage} alt="bitcoin" className="h-4 mr-2 my-auto"/>
                                                                <Typography variant="paragraph">0.23</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Typography variant="lead">yatseen.eth to steddy.eth</Typography>
                                                        <Typography variant="paragraph" className="text-center lg:text-right">5 Days ago</Typography>
                                                    </div>
                                                </div>
                                                <div className="py-4 px-2 flex flex-col lg:flex-row justify-between items-center border-b border-b-2 border-b-white/[.2] hover:bg-[#232323] cursor-pointer">
                                                    <div className="flex">
                                                        <img src={transterImage} alt="transfer_image" className="h-6 mt-1 mr-3"/>
                                                        <div>
                                                            <Typography variant="lead">Transfer</Typography>
                                                            <div className="flex mt-1">
                                                                <img src={bitcoinImage} alt="bitcoin" className="h-4 mr-2 my-auto"/>
                                                                <Typography variant="small">0.23</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Typography variant="lead">yatseen.eth to steddy.eth</Typography>
                                                        <Typography variant="paragraph" className="text-center lg:text-right">5 Days ago</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}