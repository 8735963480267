import React from "react";
import { toast } from "react-toastify";
import useSharedState from '../../hooks/useSharedState';
import ImagesDatas from "../../config/image";
import { useNavigate } from "react-router-dom";
import {postCollectionData, getCollections} from "../../utils/api";
import {config} from "./../../config/config";
import {
    Typography, 
    Card,
    CardHeader,
    CardBody } from "@material-tailwind/react";

export default function Collection(props) {
    let j=0;
    const navigatePath = useNavigate();
    const { favoriteIcon,spline1Image,heroImage,NftAvatar }=ImagesDatas();

    const {collectionDatas, setCollectionDatas, isNight, userWalletData}=useSharedState();
    const [active, setActive] = React.useState(1);
    
    const [imageFile, setImageFile] = React.useState('');
    const [collectionName, setCollectionName] = React.useState('');
    const [collectionDes, setCollectionDes] = React.useState('');
    const [contentURL, setContentURL] = React.useState('');
    const [socialURL, setSocialURL] = React.useState('');
    
    const upload = () => {
        if (imageFile && collectionName) {
            const item = collectionDatas.find(item=>item.collection_name==collectionName);
            if(item){
                toast.error('The collection name is existed');
                return;
            }

            let data = new FormData();
            data.append("collection_image", imageFile);
            data.set("owner", userWalletData['profile']['btcAddress']['p2wpkh'].mainnet);
            data.set("collection_name", collectionName);
            data.set("collection_description", collectionDes);
            data.set("collection_url", contentURL);
            data.set("social_url", socialURL);

            postCollectionData(data);
        }
        if(!imageFile) {
            toast.error('Select the image file');
            return;
        }
        if(!collectionName){
            toast.error('Type the collection name');
            return;
        }
    }

    React.useEffect(() => {
        getCollections("").then(({data})=> {
            const temp = [];
            console.log("getAllCollections = ", data['data']);
    
            data['data'].map((item, i) => {
                if((item.collection_image).slice(0,4)!='http') {
                    temp.push(item);
                    temp[i].collection_image = config.baseUrl+item.collection_image;
                }
                else
                    temp.push(item);
            });

            if(temp !== undefined && temp.length > 0)
                setCollectionDatas(temp);
        });

        if(!userWalletData && active==2)
            toast.error('Please connect wallet!');
    },[active, setCollectionDatas, userWalletData]);

    return <React.Fragment>
        <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
            <div className="absolute left-0 top-[43%] lg:top-[73%] z-10">
                <img src={spline1Image} alt="spline1Image" className="w-full"/>
            </div>
            <div className="absolute left-[50%] top-0 z-10">
                <img src={heroImage} alt="hero" className="w-full"/>
            </div>
            <div className="relative pt-48 bg-cover bg-center h-full z-50">
                <div className="container p-3 mx-auto lg:px-16">
                    <div className="pt-8 lg:pt-20">
                        {active==1 &&
                            <p className="text-3xl lg:text-5xl">Explore Collection</p>
                        }
                        {active==2 &&
                            <p className="text-3xl lg:text-5xl">Mint Collection</p>
                        }
                        <ul className="flex gap-5 mt-10">
                            <li className="cursor-pointer" onClick={() => {setActive(1);}}>
                                <Typography variant="lead" className={(active===1?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Recent</Typography>
                            </li>
                            <li className="cursor-pointer" onClick={() => {setActive(2);}}>
                                <Typography variant="lead" className={(active===2?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Minting Now</Typography>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {active===1 &&
            <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1 mt-4 lg:mt-8 z-50">
                <div className="container px-3 mx-auto lg:px-16">
                    {collectionDatas.map((collectionData, i) => {
                        if(i%3===0){
                            return(
                                <div key={j++} className="flex flex-col lg:flex-row pt-5 px-5 lg:px-0 gap-10 justify-between items-center">
                                    {collectionDatas[i] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="text-gray">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                    {collectionDatas[i+1] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i+1]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i+1].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="text-gray">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i+1].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                    {collectionDatas[i+2] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i+2]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i+2].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="text-gray">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i+2].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        }
        {active===2 &&
            <div className="relative z-50 container mx-auto mt-4 lg:mt-8 px-3 mx-auto lg:px-16">
                {userWalletData !== null &&
                    <div className="flex flex-col gap-5">
                        <input className="w-full bg-blue-200/[.05] p-3 text-[13px]" placeholder="wallet address" type="text" value={(userWalletData['profile']['btcAddress']['p2wpkh'].mainnet).toUpperCase()} readOnly />
                        <input className="w-full bg-blue-200/[.05] p-3 text-[13px]" placeholder="collection name" type="text" value={collectionName} onChange={(e)=>{setCollectionName(e.target.value)}} />
                        <input className="w-full bg-blue-200/[.05] p-3 text-[13px]" placeholder="collection description" type="text" value={collectionDes} onChange={(e)=>{setCollectionDes(e.target.value)}} />
                        <input className="w-full bg-blue-200/[.05] p-3 text-[13px]" placeholder="content URL" type="text" value={contentURL} onChange={(e)=>{setContentURL(e.target.value)}} />
                        <input className="w-full bg-blue-200/[.05] p-3 text-[13px]" placeholder="social link" type="text" value={socialURL} onChange={(e)=>{setSocialURL(e.target.value)}} />
                        <input type="file" onChange={(e)=>{setImageFile(e.target.files[0])}} />
                        <input className="w-full border border-ordinsea-fontYellow rounded-full hover:bg-ordinsea-fontYellow p-3 cursor-pointer" type="button" onClick={upload} value="Upload" />
                    </div>
                }
                {userWalletData === null &&
                    <div className="h-[50vh]"></div>
                }
            </div>
        }
    </React.Fragment>;
}