import React from "react";
import { toast } from "react-toastify";
import { config } from '../../config/config';
import useSharedState from '../../hooks/useSharedState';
import { useCountries } from "use-react-countries";
import { useNavigate } from "react-router-dom";
import ImagesDatas from '../../config/image';
import {getOrdinalsByWalletAddress, getOrdinals, getCollections, getAllActivityByUser} from "../../utils/api";

import {
    Select,
    Option,
    Typography,
    Card,
    CardHeader,
    CardBody,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    IconButton,
    Button,
    Input,
    Switch, } from "@material-tailwind/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import ActivityIcon from "../../components/ActivityIcon";

export default function Whitepaper(props) {
    let j=0;
    const navigatePath = useNavigate();
    const { countries } = useCountries();
    const [active, setActive]=React.useState(1);
    
    const [unsignedSellerPsbt, setUnsignedSellerPsbt] = React.useState('');
    const [selectedOrdinal, setSelectedOrdinal] = React.useState('');

    const [collectionName, setCollectionName] = React.useState('');
    const [ordinalName, setOrdinalName] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [ordinalActivities, setOrdinalActivities]=React.useState([]);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);

    const {NftAvatar, cartImage, listImage, offersImage, transterImage, spline1Image, heroImage, bitcoinImage}=ImagesDatas();
    const {activityIcons, collectionDatas, setCollectionDatas, isNight, userWalletData, ordinalsOfWhitepaper, setOrdinalsOfWhitepaper}=useSharedState();

    const generateUnsignedSellerPsbt = async () => {
        console.log('generateUnsignedSellerPsbt==', price, collectionName, ordinalName);

        setUnsignedSellerPsbt('');
        const res = await fetch(`${config.baseUrl}/listing`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "ordinal_output": selectedOrdinal["inscription"].output,
                "ordinal_output_value": selectedOrdinal["inscription"].value,
                "tap_internal_key": userWalletData['profile']['btcAddress']['p2tr'].mainnet,
                "price": price,
                "maker_fee_bp": 1,
                "seller_receive_address": userWalletData['profile']['btcAddress']['p2wpkh'].mainnet,
            }),
        });
        const data = await res.json();
        if (data.status === 'success') {
            console.log('data.listingState.seller.unsignedListingPSBTBase64==', data.listingState.seller.unsignedListingPSBTBase64);
            setUnsignedSellerPsbt(data.listingState.seller.unsignedListingPSBTBase64);
        } else {
            setOpen(!open);
            toast.error('Listing error!');
            console.error(data.status);
        }
    }

    const getOrdinalData = async(ordinals) => {
        const results = await Promise.all(ordinals.map(async ordinal => {
            const result = await getOrdinals(ordinal.id);
            return result.data;
        }));
        console.log('getOrdinalsOfWhitepaper====', results);
        setOrdinalsOfWhitepaper(results);
    }

    React.useEffect(() => {
        getAllActivityByUser('bc1prh0cd85fvtdqpte4anvu78ma49rffq5ljjm35a78wvxev6ynh58sxjvvts').then(({data})=> {
            console.log("getAllActivityByUser = ", data['data']);
            const temp = [];

            data['data'].map((item, i) => {
                temp.push(item);
            });
            
            if(temp !== undefined && temp.length > 0)
                setOrdinalActivities(temp);
        });

        getOrdinalsByWalletAddress('bc1prh0cd85fvtdqpte4anvu78ma49rffq5ljjm35a78wvxev6ynh58sxjvvts').then(({data})=> {
            console.log('getOrdinalsByWalletAddress==', data);
            getOrdinalData(data);
        });

        // collections with address, display on modal
        const body = {
            "wallet_address": "bc1prh0cd85fvtdqpte4anvu78ma49rffq5ljjm35a78wvxev6ynh58sxjvvts"
        }
        getCollections("", body).then(({data})=> {
            const temp = [];
            console.log("getCollectionsByWallet = ", data['data']);
            
            data['data'].map((item, i) => {
                temp.push(item);
            });
            
            if(temp !== undefined && temp.length > 0)
                setCollectionDatas(temp);
        });

        if(!userWalletData)
            toast.error('Please connect wallet!');
    },[setCollectionDatas, setOrdinalsOfWhitepaper, userWalletData, active]);

    return <React.Fragment>
        <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1 border-b border-b-2 border-[#4A4A76]">
            <div className="absolute left-0 top-[15%] lg:top-[35%] z-10">
                <img src={spline1Image} alt="spline1Image" className="w-full"/>
            </div>
            <div className="absolute left-[50%] top-0 z-10">
                <img src={heroImage} alt="hero" className="w-full"/>
            </div>
            <div className="container p-3 mx-auto lg:px-16 z-50 relative">
                <div className="pt-48 pb-32 flex flex-col lg:flex-row justify-between items-center">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <img src={NftAvatar} alt="avatar" className="pr-5 w-24" />
                        <div className=" ">
                            <Typography variant="h3">yatseen.eth</Typography>
                            {userWalletData !==null &&
                                <Typography variant="lead">{(userWalletData['profile']['btcAddress']['p2wpkh'].mainnet).slice(0,7)}...{(userWalletData['profile']['btcAddress']['p2wpkh'].mainnet).slice(38)}</Typography>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <Typography variant="lead" className="pr-0 mt-3 lg:mt-0 lg:pr-5">0 Followers</Typography>
                        <Typography variant="lead" className="pr-0 mt-3 lg:mt-0 lg:pr-5">0 Following</Typography>
                        <button className="mt-3 lg:mt-0 px-8 py-3 border border-ordinsea-fontYellow rounded-full hover:bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button"><Typography variant="lead">Follow</Typography></button>
                    </div>
                </div>
            </div>
        </div>

        <div className="container p-3 mx-auto lg:px-16  ">
            <div className="mt-10 flex flex-col lg:flex-row">
                <ul className="flex gap-5 mr-16 my-auto">
                    <li className="cursor-pointer" onClick={() => {setActive(1)}}>
                        <Typography variant="lead" className={(active===1?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Activity</Typography>
                    </li>
                    <li className="cursor-pointer" onClick={() => {setActive(2)}}>
                        <Typography variant="lead" className={(active===2?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Items</Typography>
                    </li>
                </ul>
            </div>
            {active===1 &&
                <div className="mt-16">
                    <Typography variant="h3">Activity</Typography>
                    <div className="flex flex-col lg:flex-row lg:gap-10 mt-5">
                        <div className="w-full lg:w-1/3 mb-10">
                            <Typography variant="h4" className=" ">Event Type</Typography>
                            <div className="mt-5 flex justify-between items-center">
                                <div className="flex">
                                    <img src={cartImage} alt="cart" className="pr-2"/>
                                    <Typography variant="lead" className=" ">Sales</Typography>
                                </div>
                                <Switch id="ripple-on1" ripple={true} className="bg-ordinsea-fontYellow"/>
                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <div className="flex">
                                    <img src={listImage} alt="cart" className="pr-2"/>
                                    <Typography variant="lead" className=" ">Listings</Typography>
                                </div>
                                <Switch id="ripple-on2" ripple={true} className="bg-ordinsea-fontYellow"/>
                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <div className="flex">
                                    <img src={offersImage} alt="cart" className="pr-2"/>
                                    <Typography variant="lead" className=" ">Offers</Typography>
                                </div>
                                <Switch id="ripple-on3" ripple={true} className="bg-ordinsea-fontYellow"/>
                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <div className="flex">
                                    <img src={transterImage} alt="cart" className="pr-2"/>
                                    <Typography as="p" variant="lead" className=" ">Transfer</Typography>
                                </div>
                                <Switch id="ripple-on4" ripple={true} className="bg-ordinsea-fontYellow"/>
                            </div>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b border-b-2 border-b-white/[.2]">
                                        <td className="pb-5 px-5"><Typography variant="h4">Collected</Typography></td>
                                        <td className="pb-5 px-5"><Typography variant="h4">Quantity</Typography></td>
                                        <td className="pb-5 px-5"><Typography variant="h4">From</Typography></td>
                                        <td className="pb-5 px-5"><Typography variant="h4">To</Typography></td>
                                        <td className="pb-5 px-5"><Typography variant="h4">Time</Typography></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    activityIcons.map((activityIcon,i) => {
                                        return(
                                            <tr key={i} className="border-b border-b-2 border-b-white/[.2] hover:bg-[#232323] cursor-pointer">
                                                <td className="whitespace-nowrap p-5">
                                                    <ActivityIcon image={activityIcon.image} label={activityIcon.label} id={activityIcon.id}/>
                                                </td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.quality}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.from}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.to}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.time}</Typography></td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {active===2 &&
                <div className="mt-8 lg:mt-16">
                    {ordinalsOfWhitepaper.map((nftItem, i) => {
                        if(i%3===0){
                            return(
                                <div key={j++} className="flex flex-col lg:flex-row pt-5 px-5 lg:px-0 gap-10 justify-between items-center">
                                    {ordinalsOfWhitepaper[i] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground bg-ordinsea-cardBackground "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")}>
                                            <CardHeader floated={false} className="h-full border border-ordinsea-cardBackground bg-transparent shadow-none">
                                                <img src={"https://ordinals.com/content/"+ordinalsOfWhitepaper[i].inscription_id} alt="profile" className="w-full h-72" />
                                            </CardHeader>
                                            <CardBody>
                                                <div className={"flex flex-row justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i].ordinal_name}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i].collection_name}
                                                        </Typography>
                                                    </div>
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i].ordinal_number}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i]['inscription'].number}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row mt-5 justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex">
                                                        <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                        <Typography variant="lead">{ordinalsOfWhitepaper[i].price}</Typography>
                                                    </div>
                                                    <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{setOpen(!open); setSelectedOrdinal(ordinalsOfWhitepaper[i]);}}><Typography variant="lead">Listing</Typography></button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full h-full hidden lg:block"></div>
                                    )}
                                    {ordinalsOfWhitepaper[i+1] !== undefined ? (
                                        <Card className="w-full h-full bg-ordinsea-cardBackground">
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <img src={"https://ordinals.com/content/"+ordinalsOfWhitepaper[i+1].inscription_id} alt="profile" className="w-full h-72" />
                                            </CardHeader>
                                            <CardBody>
                                                <div className="flex flex-row justify-between items-center">
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i+1].ordinal_name}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i+1].collection_name}
                                                        </Typography>
                                                    </div>
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i+1].ordinal_number}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i+1]['inscription'].number}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="mt-5 flex flex-row justify-between items-center  ">
                                                    <div className="flex">
                                                        <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                        <Typography variant="lead">{ordinalsOfWhitepaper[i+1].price}</Typography>
                                                    </div>
                                                    <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{setOpen(!open); setSelectedOrdinal(ordinalsOfWhitepaper[i+1]);}}><Typography variant="lead">Listing</Typography></button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                    {ordinalsOfWhitepaper[i+2] !== undefined ? (
                                        <Card className="w-full h-full bg-ordinsea-cardBackground">
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <img src={"https://ordinals.com/content/"+ordinalsOfWhitepaper[i+2].inscription_id} alt="profile" className="w-full h-72" />
                                            </CardHeader>
                                            <CardBody>
                                                <div className="flex flex-row justify-between items-center">
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i+2].ordinal_name}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i+2].collection_name}
                                                        </Typography>
                                                    </div>
                                                    <div className="my-auto">
                                                        <Typography variant="lead">
                                                            {ordinalsOfWhitepaper[i+2].ordinal_number}
                                                        </Typography>
                                                        <Typography variant="paragraph">
                                                            {ordinalsOfWhitepaper[i+2]['inscription'].number}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="mt-5 flex flex-row justify-between items-center  ">
                                                    <div className="flex">
                                                        <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                        <Typography variant="lead">{ordinalsOfWhitepaper[i+2].price}</Typography>
                                                    </div>
                                                    <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{setOpen(!open); setSelectedOrdinal(ordinalsOfWhitepaper[i+2]);}}><Typography variant="lead">Listing</Typography></button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                </div>
                            )
                        }
                    })}                        
                </div>
            }
        </div>

        <Dialog size="xs" open={open} handler={handleOpen} className="w-full min-w-[100%] max-w-[100%] lg:min-w-[25%] lg:max-w-[25%] lg:w-1/4">
            <DialogHeader className="justify-between">
                <Typography variant="h5" color="blue-gray">
                    Listing ordinal
                </Typography>
                <IconButton
                    color="blue-gray"
                    size="sm"
                    variant="text"
                    onClick={handleOpen}
                >
                    <XMarkIcon strokeWidth={2} className="h-5 w-5" />
                </IconButton>
            </DialogHeader>
            <DialogBody className="pr-2">
                <div>
                    <ul className="mt-1 -ml-2 flex flex-col gap-5 px-3">
                        <Select
                            size="lg"
                            label="Select Collection"
                            variant="standard"
                            selected={(element) =>
                                element &&
                                React.cloneElement(element, {
                                    className: "flex items-center px-0 gap-2 pointer-events-none",
                                })
                            }
                            onChange={(value) => {
                                setCollectionName(value);
                            }}
                        >
                            {collectionDatas.map((collectionData, i) => (
                                <Option key={i} value={collectionData.collection_name} defaultValue className="flex items-center gap-2">
                                    <img
                                        src={collectionData.collection_image}
                                        alt={collectionData.collection_name}
                                        className="h-5 w-5 rounded-full object-cover"
                                    />
                                    {collectionData.collection_name}
                                </Option>
                            ))}
                        </Select>
                        <Input variant="standard" label="Ordinal name" value={ordinalName} onChange={(e) => {setOrdinalName(e.target.value);}}/>
                        <Input variant="standard" label="Price" value={price} onChange={(e) => {setPrice(e.target.value);}}/>
                    </ul>
                </div>
            </DialogBody>
            <DialogFooter className="justify-between gap-2 border-t border-blue-gray-50">
                <Button variant="text" size="sm" onClick={generateUnsignedSellerPsbt}>
                    list
                </Button>
            </DialogFooter>
        </Dialog>
    </React.Fragment>;
}