import axios from 'axios';
import * as connect from '@stacks/connect';
import { config } from '../config/config';
import { toast } from "react-toastify";

// const header = {
//     headers: {
//         "Content-Type": "application/json",
//     },
// }

// export const updateUserScore = (address, scores) => {
//     axios
//     .post(`${baseUrl}/user/score`, {
//         address: address,
//         scores: scores,
//     })
//     .catch((err) => console.error(err));
// }

// export const getUserRank = (address) => {
//     return axios.get(`${baseUrl}/user/user_rank/${address}`).catch((err) => console.error(err));
// }

// export const getAllUser = () => {
//     return axios.get(`${baseUrl}/user/all_user/`).catch((err) => console.error(err));
// }

// export const getChart = async (data) => {
//     const {address, resolution} = data;
//     const res = await fetch(`${baseUrl}/api/chart?address=${address}&resolution=${resolution}`, {
//         method: 'GET',
//         header,
//     });
//     return await res.json();
// };

// export const getAllOrdinalsFetch = async () => {
//     const res = await fetch(`${baseUrl}/ordinals/`, {
//         method: 'GET',
//         header,
//     });
//     return await res.json();
// };

// export const signPsbtWithWallet = async (psbtBase64) => {
//     return new Promise((resolve, reject) => {
//         connect.openPsbtRequestPopup({
//             network: BTC_NETWORK === 'mainnet' ? StacksMainnet : StacksTestnet,
//             appDetails: {
//             name: 'Ordinsea',
//             icon: window.location.origin + '/favicon.ico',
//             },
//             userSession: userSession,
//             hex: base64ToHex(psbtBase64),
//             allowedSighash: [0x01, 0x02, 0x03, 0x81, 0x82, 0x83],
//             signAtIndex: signAtIndex,
//             onFinish: data => resolve(data.hex),
//             onCancel: () => reject(new Error('Hiro wallet canceled signing request')),
//         });
//     });
// }

// export const signSellerPsbt = async () => {
//     setSignedSellerPsbt('');
//     setSignedSellerPsbt(await signPsbtWithWallet(unsignedSellerPsbt));
// }

export const postCollectionData = async (body) => {
    return await axios
        .post(`${config.baseUrl}/collections/insert/`, body)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                toast.success('Collection mint successful!');
            } else {
                toast.error('Collection mint failed!');
            }
        })
        .catch(error => console.log(error));

    // await fetch(`${config.baseUrl}/collections/insert/`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //         "ordinal_output": "1b1eb4f894bee8cf3bccf32d8a720e10dc5d238a2b811652a2f8d718c2b22b48:0",
    //         "ordinal_output_value": 10000,
    //         "tap_internal_key": "02b6871d40c27b625fc7f6614b36f690c06d9037a5a6c0e605ca78c9da9e8507f0",
    //         "price": 10000,
    //         "maker_fee_bp": 1,
    //         "seller_receive_address": "tb1qx27tl2hnfrujs8e48atdf7rjppzdgts9y8j943",
    //     }),
    // })
    // .then(response => console.log(response))
    // .catch(error => console.log(error));
}

export const getOrdinalsByWalletAddress = async (address) => {
    return await axios.get(`https://ordapi.xyz/address/${address}`).catch((err) => console.error(err));
}

export const getOrdinals = async (ordinalId) => {
    if(ordinalId === "") {
        return await axios.get(`${config.baseUrl}/ordinals`).catch((err) => console.error(err));
    }
    else {
        return await axios.get(`${config.baseUrl}/ordinals/${ordinalId}`).catch((err) => console.error(err));
    }
}

export const getCollections = async (collectionId, body) => {
    if(collectionId === ""){
        if(body){
            return await axios.post(`${config.baseUrl}/collections`, body).catch((err) => console.error(err));
        }
        else
            return await axios.post(`${config.baseUrl}/collections`).catch((err) => console.error(err));
    }
    else {
        return await axios.get(`${config.baseUrl}/collections/${collectionId}/ordinals`).catch((err) => console.error(err));
    }
}

export const getCollectionData = async (collectionId) => {
    return await axios.get(`${config.baseUrl}/collections/${collectionId}`).catch((err) => console.error(err));
}

export const getOrdinalActivity = async (inscription_id) => {
    return await axios.get(`${config.baseUrl}/activities/ordinal/${inscription_id}`).catch((err) => console.error(err));
}

export const getAllActivityByUser = async (wallet_address) => {
    return await axios.get(`${config.baseUrl}/activities/user/${wallet_address}`).catch((err) => console.error(err));
}