import styled from "styled-components";

import Header from "./header/Header";
import Footer from "./footer/Footer";

const Page = styled.div`
  min-height: calc(100vh - 180px);
`;

export default function MainLayout(props) {
  return (
    <div>
      <Header />
      <Page>
        {props.children}
      </Page>
      <Footer/>
    </div>
  );
}
