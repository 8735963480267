import React from "react";
import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import symbolIcon from "../assets/img/icon/bitcoin.png";

export default function CollectionIcon(props) {
    const [isNavOpen, setIsNavOpen] = React.useState(false);
    const navigatePath = useNavigate();

    React.useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <div className="flex flex-row mt-5 lg:mt-0 cursor-pointer" onClick={()=>{navigatePath('/ordinals')}}>
                <img className="w-12 h-12 rounded-full my-auto" src={props.image} alt="toToken" />
                <div className="pl-3">
                    <div className="">
                        <Typography variant="lead" className="mt-1">{props.label}</Typography>
                        <div className="flex flex-row">
                            <div className="flex flex-row">
                                <Typography variant="paragraph" className="mt-1">Floor:</Typography>
                                <img className="w-6 px-1 my-auto" src={symbolIcon} alt="symbol" />
                                <Typography variant="paragraph" className="mt-1">{props.floorValue}</Typography>
                            </div>
                            <div className="flex flex-row pl-3">
                                <Typography variant="paragraph" className="mt-1">Volume:</Typography>
                                <img className="w-6 px-1 my-auto" src={symbolIcon} alt="symbol" />
                                <Typography variant="paragraph" className="mt-1">{props.volumeValue}</Typography>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    );    
}