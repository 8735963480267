import ic_logo from "../../src/assets/img/logo.png";
import ic_logo_b from "../../src/assets/img/logo_b.png";
import favicon from "../../src/assets/img/icon/favicon.png";
import day_icon from "../../src/assets/img/icon/day_icon.png";
import day_icon_b from "../../src/assets/img/icon/day_icon_b.png";
import dollarIcon from "../../src/assets/img/icon/dollar.png";
import dollarIcon_b from "../../src/assets/img/icon/dollar_b.png";
import connectIcon from "../../src/assets/img/icon/connect.png";
import connectIcon_b from "../../src/assets/img/icon/connect_b.png";

import bgImage from "../../src/assets/img/bg/upper_lg_2.png";

import cartImage from "../../src/assets/img/icon/event/basket.png";
import listImage from "../../src/assets/img/icon/event/listings.png";
import offersImage from "../../src/assets/img/icon/event/offers.png";
import transterImage from "../../src/assets/img/icon/event/transfer.png";

import bitcoinImage from "../../src/assets/img/icon/bitcoin.png";
import searchImage from "../../src/assets/img/icon/search.png";

import favoriteIcon from "../../src/assets/img/icon/favorite.png";
import partnerImage1 from "../../src/assets/img/icon/partners/partner1.png";
import partnerImage2 from "../../src/assets/img/icon/partners/partner2.png";
import partnerImage3 from "../../src/assets/img/icon/partners/partner3.png";
import partnerImage4 from "../../src/assets/img/icon/partners/partner4.png";
import partnerImage5 from "../../src/assets/img/icon/partners/partner5.png";

import memberImage1 from "../../src/assets/img/icon/membership/member1.png";
import memberImage2 from "../../src/assets/img/icon/membership/member2.png";
import memberImage3 from "../../src/assets/img/icon/membership/member3.png";
import memberImage4 from "../../src/assets/img/icon/membership/member4.png";

import cardImage from "../../src/assets/img/bg/card.png";
import NftDetailImage from "../../src/assets/img/nft_detail/Vector.png";
import instagramImage from "../../src/assets/img/icon/social/instagram.png";
import twitterImage from "../../src/assets/img/icon/social/twitter1.png";
import discordImage from "../../src/assets/img/icon/social/discord.png";

import twitter from "../../src/assets/img/icon/social/twitter.png";
import twitter_b from "../../src/assets/img/icon/social/twitter_b.png";
import telegram from "../../src/assets/img/icon/social/tele.png";
import telegram_b from "../../src/assets/img/icon/social/tele_b.png";
import medium from "./../../src/assets/img/icon/social/medium.png";
import medium_b from "./../../src/assets/img/icon/social/medium_b.png";

import NftAvatar from "../assets/img/icon/NftAvatar/Avatar1.png";

import heroImage from "../assets/img/bg/hero.png";
import spline1Image from "../assets/img/bg/Vector6.png";
import spline2Image from "../assets/img/bg/Vector14.png";
import ellipseImage from "../assets/img/bg/Ellipse.png";

import ic_wrong from "../assets/img/networks/wrong.png";
import ic_metamask from "../assets/img/wallets/metamask.svg";
import ic_walletConnect from "../assets/img/wallets/walletconnect.svg";

import HiroWallet from "../assets/img/networks/HiroWallet.jpg";
import XverseWallet from "../assets/img/networks/XverseWallet.png";

import badge from "../../src/assets/img/bg/Vector.png";

const ImagesDatas = () => {
    return {
        ic_logo,
        ic_logo_b,
        day_icon,
        day_icon_b,
        dollarIcon,
        dollarIcon_b,
        connectIcon,
        connectIcon_b,
        bgImage,
        cardImage,
        cartImage,
        listImage,
        offersImage,
        transterImage,
        bitcoinImage,
        searchImage,
        favoriteIcon,
        partnerImage1,
        partnerImage2,
        partnerImage3,
        partnerImage4,
        partnerImage5,
        memberImage1,
        memberImage2,
        memberImage3,
        memberImage4,
        NftDetailImage,
        instagramImage,
        twitterImage,
        discordImage,
        twitter,
        twitter_b,
        telegram,
        telegram_b,
        medium,
        medium_b,
        NftAvatar,
        heroImage,
        spline1Image,
        spline2Image,
        ellipseImage,
        ic_wrong,
        ic_metamask,
        ic_walletConnect,
        HiroWallet,
        XverseWallet,
        favicon,
        badge
    }
};

export default ImagesDatas;
