import React from "react";
import Chart from 'react-apexcharts'
import useSharedState from '../../hooks/useSharedState';
import { useNavigate } from "react-router-dom";
import ImagesDatas from '../../config/image';
import {getCollections, getCollectionData, getOrdinals} from "../../utils/api";

import {
    Typography,
    Card,
    CardHeader,
    CardBody,
    Select,
    Option,
    Switch, } from "@material-tailwind/react";

import ActivityIcon from "../../components/ActivityIcon";

export default function OrdinalsView(props) {
    let j=0;
    const navigatePath = useNavigate();
    const initialValue = {
        optionsMixedChart: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: true
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.8,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                },
            },
            colors: ['#FFC300'],
            dataLabels: {
                enabled: false,
                show: false
            },
            // stroke: {
            //     curve: 'smooth',
            //     width: 2,
            // },
            plotOptions: {
                bar: {
                    columnWidth: "100%",
                    colors: {
                        ranges: [
                            {
                                from: 0,
                                to: 150,
                                color: '#8B8B8B',
                            },
                            // {
                            //     from: 51,
                            //     to: 100,
                            //     color: '#FF4560',
                            // },
                        ],
                    },
                }
            },
            stroke: {
                width: [2, 0, 0]
            },
            xaxis: {
                categories: ["May 23", "June 23", "Jul 23", "Aug 23", "Sep 23", "Oct 23", "Nov 23", "Dec 23", "Jan 23", "Feb 23", "Mar 23", "Apr 23"]
            },
            markers: {
                size: 0,
                strokeWidth: 0,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                    size: 5
                }
            },
            yaxis: [
                {
                    name: 'series-1',
                    opposite: true,
                    tickAmount: 5,
                    min: 0,
                    max: 10
                },
                {
                    name: 'series-2',
                    tickAmount: 5,
                    min: 0,
                    max: 150,
                },
                {
                    name: 'series-3',
                    tickAmount: 5,
                    min: 0,
                    max: 150,
                    show: false
                },
                {
                    name: 'series-4',
                    tickAmount: 5,
                    min: 0,
                    max: 150,
                    show: false
                },
            ]
        },
        seriesMixedChart: [
            {
                name: "series-1",
                type: "line",
                data: [0, 5, 2, 6, 9, 8, 3, 10, 2, 7, 5, 4]
            },
            {
                name: "series-2",
                type: "column",
                data: [20, 18, 54, 61, 30, 56, 100, 19, 23, 12, 54, 150]
            },
            {
                name: "series-3",
                type: "column",
                data: [10, 12, 6, 23, 32, 12, 81, 3, 23, 12, 54, 61]
            },
            {
                name: "series-4",
                type: "column",
                data: [6, 12, 8, 61, 32, 7, 4, 6, 23, 12, 7, 61]
            },
        ],
    };

    const {ellipseImage, cartImage, listImage, offersImage, transterImage, bitcoinImage, searchImage}=ImagesDatas();
    const {activityIcons, ordinalDatas, setOrdinalDatas, isNight}=useSharedState();

    const [active, setActive]=React.useState(1);
    const [chartData, setCharData]=React.useState(initialValue);
    const [collectionInfo, setCollectionInfo]=React.useState('');

    const getOrdinalData = async(ordinals) => {
        const results = await Promise.all(ordinals.map(async ordinal => {
            const result = await getOrdinals(ordinal.ordinal_id);
            return result.data;
        }));
        console.log('getOrdinalsForCollection====', results);
        setOrdinalDatas(results);
    }

    React.useEffect(() => {
        // getAllOrdinalActivity
        getCollectionData(props.collection.collection_id).then(({data})=> {
            console.log('getCollectionData==', data['data'][0]);
            // getOrdinalData(data['data']);
            setCollectionInfo(data['data'][0]);
        });

        getCollections(props.collection.collection_id).then(({data})=> {
            console.log('getOrdinalsIdForCollection==', data['data']);
            getOrdinalData(data['data']);
        });
    },[props.collection.collection_id, setOrdinalDatas]);

    return <React.Fragment>
        <div className="bg-cover bg-center bg-no-repeat bg-upperBg h-[400px]">
        </div>
        <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
            <div className="container mx-auto p-3 lg:px-16">
                <img src={props.collection.collection_image} alt="hero" className="w-48 h-48 border border-4 border-ordinsea-fontYellow rounded-full mt-[-150px]"/>
                <div className="mt-16  ">
                    <Typography variant="h3">Ey3k0n First Edition</Typography>
                    <Typography variant="lead">Inscriptions #383632-551340</Typography>        
                </div>
                <div className="flex flex-col lg:flex-row   mt-5">
                    <div className="flex pr-4">
                        <Typography variant="lead" className="pr-2">Created: </Typography>
                        <Typography variant="lead" className="font-semibold">Apr 2022</Typography>
                    </div>
                    <div className="flex pr-4">
                        <Typography variant="lead" className="pr-2">Creator earnings: </Typography>
                        <Typography variant="lead" className="font-semibold">5%</Typography>
                    </div>
                    <div className="flex pr-4">
                        <Typography variant="lead" className="pr-2">Chain: </Typography>
                        <Typography variant="lead" className="font-semibold">Bitcoin</Typography>
                    </div>
                    <div className="flex">
                        <Typography variant="lead" className="pr-2">Category: </Typography>
                        <Typography variant="lead" className="font-semibold">Virtual Worlds</Typography>
                    </div>
                </div>
                <div className="mt-2  ">
                    <Typography variant="paragraph">Ut quis turpis eget justo scelerisque tincidunt. Nulla facilisi. Nunc sed mi a augue pretium feugiat. Integer sagittis aliquam eros, id</Typography>
                    <Typography variant="paragraph">pharetra sapien ultrices vel. Morbi cursus dui odio, id tempor ex imperdiet sed. Ut sodales ex ac ex sodales, ut mattis lorem porttitor.</Typography>
                    <Typography variant="paragraph">Morbi quis sem tellus.</Typography>
                </div>
            </div>
        </div>

        <div className="container mx-auto p-3 lg:px-16">    
            <div className="mt-8 flex flex-col lg:flex-row justify-between items-center  ">
                <div className="mt-5 lg:mt-0">
                    <div className="flex flex-row">
                        <Typography variant="lead" className="pr-2">{collectionInfo.floor_price}</Typography>
                        <img src={bitcoinImage} className="" alt="bitcoin"/>
                    </div>
                    <Typography variant="paragraph">Floor Price</Typography>
                </div>
                <div className="mt-5 lg:mt-0">
                    <Typography variant="lead" className="pr-2">Sales</Typography>
                    <Typography variant="paragraph">1887</Typography>
                </div>
                <div className="mt-5 lg:mt-0">
                    <div className="flex flex-row">
                        <Typography variant="lead" className="pr-2">0.114249</Typography>
                        <img src={bitcoinImage} className="" alt="bitcoin"/>
                    </div>
                    <Typography variant="paragraph">24 HR Volume</Typography>
                </div>
                <div className="mt-5 lg:mt-0">
                    <div className="flex flex-row">
                        <Typography variant="lead" className="pr-2">{(Math.floor((collectionInfo.total_volume) * 10 ** 6))/10**6}</Typography>
                        <img src={bitcoinImage} className="" alt="bitcoin"/>
                    </div>
                    <Typography variant="paragraph">All-Time Volume</Typography>
                </div>
                <div className="mt-5 lg:mt-0">
                    <Typography variant="lead" className="pr-2">952</Typography>
                    <Typography variant="paragraph">Listed</Typography>
                </div>
                <div className="mt-5 lg:mt-0">
                    <Typography variant="lead" className="pr-2">9904</Typography>
                    <Typography variant="paragraph">Supply</Typography>
                </div>
            </div>
            <div className="relative pt-8 z-50">
                <div className="mt-10 flex flex-col lg:flex-row">
                    <ul className="flex gap-5 mr-16 my-auto">
                        <li className="cursor-pointer" onClick={() => {setActive(1)}}>
                            <Typography variant="lead" className={(active===1?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Items</Typography>
                        </li>
                        <li className="cursor-pointer" onClick={() => {setActive(2)}}>
                            <Typography variant="lead" className={(active===2?"active:border-b border-b-2 border-b-ordinsea-fontYellow":"")}>Activity</Typography>
                        </li>
                    </ul>
                    {active===1 &&
                        <>
                            <div className="flex mt-3 lg:mt-0">
                                <input className="w-80 bg-blue-200/[.05] p-3 text-[13px]" placeholder="Search ordinals by inscription number or id"/>
                                <div className="relative w-5 h-5">
                                    <img src={searchImage} className="w-5 h-5 absolute top-3 right-7" alt="search"/>
                                </div>
                            </div>
                            <div className="w-72 mt-5 lg:ml-auto lg:mt-0">
                                <Select label="Select option" color="teal" className="bg-blue-200/[.05]">
                                    <Option>Recently Listed</Option>
                                    <Option>Price: Low to High</Option>
                                    <Option>Price: High to Low</Option>
                                    <Option>Inscription #: Low to High</Option>
                                    <Option>Inscription #: High to Low</Option>
                                </Select>
                            </div>
                        </>
                    }
                </div>
                {active===1?(
                    <div className="mt-4 lg:mt-8">
                        {ordinalDatas.map((nftItem, i) => {
                            if(i%3===0){
                                return(
                                    <div key={j++} className="flex flex-col lg:flex-row pt-5 px-5 lg:px-0 gap-10 justify-between items-center">
                                        {ordinalDatas[i] !== undefined ? (
                                            <Card className={"w-full h-full border border-ordinsea-cardBackground bg-ordinsea-cardBackground "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")}>
                                                <CardHeader floated={false} className="h-full border border-ordinsea-cardBackground bg-transparent shadow-none">
                                                    <img src={"https://ordinals.com/content/"+ordinalDatas[i].inscription_id} alt="profile" className="w-full max-h-72 min-h-72" />
                                                </CardHeader>
                                                <CardBody>
                                                    <div className={"flex flex-row justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i].ordinal_name}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i].collection_name}
                                                            </Typography>
                                                        </div>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i].ordinal_number}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i]['inscription'].number}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className={"flex flex-row mt-5 justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="flex">
                                                            <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                            <Typography variant="lead">{ordinalDatas[i].price}</Typography>
                                                        </div>
                                                        <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{navigatePath("/ordinal-detail");props.buyOrdinal(ordinalDatas[i])}}><Typography variant="lead">Buy Now</Typography></button>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ):(
                                            <div className="w-full hidden lg:block"></div>
                                        )}
                                        {ordinalDatas[i+1] !== undefined ? (
                                            <Card className="w-full h-full bg-ordinsea-cardBackground">
                                                <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                    <img src={"https://ordinals.com/content/"+ordinalDatas[i+1].inscription_id} alt="profile" className="w-full max-h-72 min-h-72" />
                                                </CardHeader>
                                                <CardBody>
                                                    <div className={"flex flex-row justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i+1].ordinal_name}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i+1].collection_name}
                                                            </Typography>
                                                        </div>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i+1].ordinal_number}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i+1]['inscription'].number}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className={"flex flex-row mt-5 justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="flex">
                                                            <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                            <Typography variant="lead">{ordinalDatas[i+1].price}</Typography>
                                                        </div>
                                                        <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{navigatePath("/ordinal-detail");props.buyOrdinal(ordinalDatas[i+1])}}><Typography variant="lead">Buy Now</Typography></button>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ):(
                                            <div className="w-full hidden lg:block"></div>
                                        )}
                                        {ordinalDatas[i+2] !== undefined ? (
                                            <Card className="w-full h-full bg-ordinsea-cardBackground">
                                                <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                    <img src={"https://ordinals.com/content/"+ordinalDatas[i+2].inscription_id} alt="profile" className="w-full max-h-72 min-h-72" />
                                                </CardHeader>
                                                <CardBody>
                                                    <div className={"flex flex-row justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i+2].ordinal_name}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i+2].collection_name}
                                                            </Typography>
                                                        </div>
                                                        <div className="my-auto">
                                                            <Typography variant="lead">
                                                                {ordinalDatas[i+2].ordinal_number}
                                                            </Typography>
                                                            <Typography variant="paragraph">
                                                                {ordinalDatas[i+2]['inscription'].number}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className={"flex flex-row mt-5 justify-between items-center "+(!isNight?"text-black":"text-white")}>
                                                        <div className="flex">
                                                            <img src={bitcoinImage} className="pr-2" alt="bitcoin"/>
                                                            <Typography variant="lead">{ordinalDatas[i+2].price}</Typography>
                                                        </div>
                                                        <button className="px-6 py-2 border border-ordinsea-fontYellow rounded-full bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight" type="button" onClick={()=>{navigatePath("/ordinal-detail");props.buyOrdinal(ordinalDatas[i+2])}}><Typography variant="lead">Buy Now</Typography></button>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ):(
                                            <div className="w-full hidden lg:block"></div>
                                        )}
                                    </div>
                                )
                            }
                        })}                        
                    </div>
                ):(
                    <div className="mt-4 lg:mt-10">
                        {/* <div className="flex flex-col lg:flex-row lg:gap-10">
                            <div className="w-full lg:w-1/3">
                                <Typography variant="h4" className=" ">Event Type</Typography>
                                <div className="mt-5 flex justify-between items-center">
                                    <div className="flex">
                                        <img src={cartImage} alt="cart" className="pr-3"/>
                                        <Typography variant="paragraph" className=" ">Sales</Typography>
                                    </div>
                                    <Switch id="ripple-on1" ripple={true} className="bg-ordinsea-fontYellow"/>
                                </div>
                                <div className="mt-5 flex justify-between items-center">
                                    <div className="flex">
                                        <img src={listImage} alt="cart" className="pr-3"/>
                                        <Typography variant="paragraph" className=" ">Listings</Typography>
                                    </div>
                                    <Switch id="ripple-on2" ripple={true} className="bg-ordinsea-fontYellow"/>
                                </div>
                                <div className="mt-5 flex justify-between items-center">
                                    <div className="flex">
                                        <img src={offersImage} alt="cart" className="pr-3"/>
                                        <Typography variant="paragraph" className=" ">Offers</Typography>
                                    </div>
                                    <Switch id="ripple-on3" ripple={true} className="bg-ordinsea-fontYellow"/>
                                </div>
                                <div className="mt-5 flex justify-between items-center">
                                    <div className="flex">
                                        <img src={transterImage} alt="cart" className="pr-3"/>
                                        <Typography variant="paragraph" className=" ">Transfer</Typography>
                                    </div>
                                    <Switch id="ripple-on4" ripple={true} className="bg-ordinsea-fontYellow"/>
                                </div>
                            </div>
                            <div className="w-full mt-5">
                                <Chart
                                    options={chartData['optionsMixedChart']}
                                    series={chartData['seriesMixedChart']}
                                    type="line"
                                    width="100%"
                                />
                            </div>
                        </div> */}
                        <div className="w-full overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b border-b-2 border-b-white/[.2]">
                                        <td className="p-5"><Typography variant="h4">Collected</Typography></td>
                                        <td className="p-5"><Typography variant="h4">Quantity</Typography></td>
                                        <td className="p-5"><Typography variant="h4">From</Typography></td>
                                        <td className="p-5"><Typography variant="h4">To</Typography></td>
                                        <td className="p-5"><Typography variant="h4">Time</Typography></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    activityIcons.map((activityIcon,i) => {
                                        return(
                                            <tr key={i} className="border-b border-b-2 border-b-white/[.2] hover:bg-[#232323] cursor-pointer">
                                                <td className="whitespace-nowrap p-5">
                                                    <ActivityIcon image={activityIcon.image} label={activityIcon.label} id={activityIcon.id}/>
                                                </td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.quality}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.from}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.to}</Typography></td>
                                                <td className="whitespace-nowrap p-5"><Typography variant="paragraph">{activityIcon.time}</Typography></td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </React.Fragment>;
}