import React from "react";
import ImagesDatas from "../../config/image";
import useSharedState from './../../hooks/useSharedState';
import {
  Typography } from "@material-tailwind/react";

export default function Footer() {
  const {isNight}=useSharedState();

  const { 
    twitter, 
    twitter_b,
    telegram,
    telegram_b,
    medium, 
    medium_b,}=ImagesDatas();

  return <React.Fragment>
    <div className="py-5 mt-24 border-t border-t-2 border-ordinsea-fontYellow">
      <div className="container px-5 mx-auto flex flex-col lg:flex-row justify-between items-center">
        <Typography variant="h6" className="mb-3 text-center lg:text-left lg:mb-0">
          Copyright @2023 Ordinsea. All Right Reserved
        </Typography>
        <div className="flex">
          <a href="https://twitter.com/ordinsea"><img src={(!isNight?twitter_b:twitter)} alt="twitter" className="w-12 pr-2" /></a>
          <a href="https://t.me/ordinsea"><img src={(!isNight?telegram_b:telegram)} alt="telegram" className="w-12 pr-2" /></a>
          <img src={(!isNight?medium_b:medium)} alt="medium" className="w-10" />
        </div>
      </div>
    </div>
  </React.Fragment>;
}
