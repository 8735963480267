import React from "react";
import ImagesDatas from "../../config/image";

const { spline1Image,heroImage }=ImagesDatas();

export default function Activity() {
    return <React.Fragment>
        <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
            <div className="absolute left-0 top-[43%] lg:top-[73%] z-10">
                <img src={spline1Image} alt="spline1Image" className="w-full"/>
            </div>
            <div className="absolute left-[50%] top-0 z-10">
                <img src={heroImage} alt="hero" className="w-full"/>
            </div>
            <div className="relative pt-48 bg-cover bg-center h-full z-50">
                <div className="container p-3 mx-auto lg:px-16">
                    <div className="pt-8 lg:pt-20">
                        <p className="text-3xl lg:text-5xl">My designer is currently working</p>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}