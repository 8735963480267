import React from "react";
import { Navbar, MobileNav, IconButton } from "@material-tailwind/react";
import useSharedState from './../../hooks/useSharedState';
import { useNavigate } from "react-router-dom";
import ImagesDatas from "../../config/image";
import { Bars2Icon } from "@heroicons/react/24/outline";

import {
  Typography } from "@material-tailwind/react";

import CollectionIcon from "../../components/CollectionIcon";
import NavListItem from "./components/NavListItem";
import Connect from "../../components/Connect";

export default function Header() {
  const {
    ic_logo,
    ic_logo_b,
    day_icon,
    day_icon_b,
    dollarIcon,
    dollarIcon_b}=ImagesDatas();

  const {isNight, setIsNight, collectionDatas, ordinalDatas, universalSearch, setUniversalSearch}=useSharedState();
  const navigatePath = useNavigate();
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
  
  const [searchedCollections, setSearchedCollections] = React.useState([]);

  const handleChange = (e) => {
    if(e.target.value=="")
      setUniversalSearch(null);
    else
      setUniversalSearch(e.target.value);
  }

  React.useEffect(() => {
    // universal search begin
    const matchingElements = [];
    for (let i = 0; i < collectionDatas.length; i++) {
      if (collectionDatas[i].collection_name.includes(universalSearch)) {
        matchingElements.push(collectionDatas[i]);
      }
    }
    setSearchedCollections(matchingElements);
    console.log('matchingElements====', searchedCollections);
    // universal search end

    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, [universalSearch]);

  return (
    <div className={"w-full h-[98px] fixed z-[100] border-b border-b-2 border-[#4A4A76] "+(!isNight?"text-black bg-white":"text-white bg-ordinsea-headerBackground")}>
      <div className="container mx-auto">
        <div className={"container text-center fixed "+(!isNight?"bg-white":"bg-ordinsea-headerBackground")}>
          <Navbar className={"h-[96px] py-5 px-1 lg:px-5 border-none backdrop-filter-none shadow-none "+(!isNight?"text-black bg-white":"text-white bg-ordinsea-headerBackground")}>
            <div className="mx-auto flex justify-between items-center">
              <div className="flex items-center">
                <img src={(!isNight?ic_logo_b:ic_logo)} className="max-h-10 pr-8 cursor-pointer" alt="ic_logo" onClick={()=>{navigatePath('/')}}/>
                <input className="w-60 rounded-full bg-blue-200/[.1] p-3 text-[13px] hidden 2xl:block" placeholder="Search Collections, NFT, Users..." value={universalSearch} onChange={handleChange}/>
                <div className="hidden lg:block ml-5">
                  <NavListItem />
                </div>
              </div>
              <div className="flex items-center">
                <button className="rounded-full border border-ordinsea-fontYellow p-3 mr-4 cursor-pointer" onClick={()=>{setIsNight(!isNight);}}>
                  <img src={(!isNight?day_icon_b:day_icon)} className="max-h-5" alt="day_icon" />
                </button>
                <div className="flex rounded-full border border-ordinsea-fontYellow py-3 px-5 mr-4 bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight hidden lg:block lg:flex cursor-pointer">
                  <img src={(!isNight?dollarIcon_b:dollarIcon)} className="max-h-6 pr-2 my-auto" alt="dollarIcon" />
                  <Typography variant="paragraph">Buy $OSEA</Typography>
                </div>
                <IconButton
                  size="sm"
                  color="blue-gray"
                  variant="text"
                  onClick={toggleIsNavOpen}
                  className="ml-auto mr-2 lg:hidden"
                >
                  <Bars2Icon className="h-6 w-6" />
                </IconButton>
                <Connect className="hidden lg:block" />
              </div>
            </div>
            <MobileNav open={isNavOpen} className={"px-5 !h-[350px] "+(!isNight?"bg-white":"bg-ordinsea-headerBackground")}>
              <NavListItem />
            </MobileNav>
          </Navbar>
        </div>
      </div>
      <div className="container mx-auto">
        <div className={"mt-[98px] px-2 lg:px-5 "+(!isNight?"text-black bg-white":"text-white bg-ordinsea-headerBackground")}>
          {searchedCollections.length > 0 &&
            <Typography variant="paragraph" className="pt-3">Collections</Typography>
          }
          <ul>
            {searchedCollections.map((item, i)=>{
              return(
                <li className="py-3" key={i}><CollectionIcon image={item.collection_image} floorValue={item.floor_price} volumeValue={item.total_volume} label={item.collection_name} /></li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
