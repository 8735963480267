import React from "react";
import useSharedState from '../../hooks/useSharedState';
import { useNavigate } from "react-router-dom";
import ImagesDatas from "../../config/image";
import {getCollections} from "../../utils/api";
import {config} from "./../../config/config";

import {
    Typography, 
    Card,
    CardHeader,
    CardBody } from "@material-tailwind/react";

import CollectionIcon from "../../components/CollectionIcon";

export default function Home(props) {
    let j=0, k=0;
    const {ordinalIconData, collectionDatas, setCollectionDatas, isNight, setIsNight}=useSharedState();
    const navigatePath = useNavigate();
    
    const { 
        favoriteIcon, 
        partnerImage1, 
        partnerImage2, 
        partnerImage3, 
        partnerImage4, 
        partnerImage5,
        memberImage1,
        memberImage2,
        memberImage3,
        memberImage4, 
        heroImage,
        spline1Image,
        spline2Image,
        NftAvatar,
        badge,
        cardImage
    }=ImagesDatas();

    const [index, setIndex] = React.useState(0);

    const replace = () => {
        if(index===collectionDatas.length-1)
            setIndex(0);
        else
            setIndex(Number(index)+1);
    }
    setTimeout(() => replace(), 500);

    React.useEffect(() => {
        getCollections("").then(({data})=> {
            const temp = [];
            console.log("getAllCollections = ", data['data']);
    
            data['data'].map((item, i) => {
                if((item.collection_image).slice(0,4)!='http') {
                    temp.push(item);
                    temp[i].collection_image = config.baseUrl+item.collection_image;
                }
                else
                    temp.push(item);
            });
    
            if(temp !== undefined && temp.length > 0)
                setCollectionDatas(temp);
        });
    },[setCollectionDatas]);

    return <React.Fragment>
    <div className="relative">
        <div className="">
            <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
                <div className="absolute left-0 top-[43%] lg:top-[73%] z-10">
                    <img src={spline1Image} alt="spline1Image" className="w-full"/>
                </div>
                <div className="absolute left-[50%] top-0 z-10">
                    <img src={heroImage} alt="hero" className="w-full"/>
                </div>
                <div className="pt-48 bg-cover bg-center h-full">
                    <div className="container mx-auto p-3 lg:px-16">
                        <div className="flex flex-col gap-24 lg:flex-row mt-12 justify-between items-center">
                            <div className="flex flex-col mb-24">
                                <p className="text-ordinsea-fontYellow text-4xl 2xl:text-5xl">INSCRIBE, BUY &</p>
                                <p className="pt-1 text-ordinsea-fontYellow text-4xl 2xl:text-5xl mb-10">SELL ORDINALS</p>
                                <Typography variant="lead" className=" ">Trustless & Secure Ordinal Marketplace Built on Bitcoin</Typography>
                                <div className="flex z-50 mt-20">
                                    <button className="w-44 lg:w-52 p-4 mr-3 lg:mr-10 rounded-full border border-ordinsea-fontYellow" type="button" onClick={()=>{navigatePath('/collection')}}><Typography className="text-base lg:text-xl">Explore Collections</Typography></button>
                                    <button className="w-44 lg:w-52 p-4 border border-ordinsea-fontYellow bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight rounded-full" type="button" onClick={()=>{navigatePath('/collection')}}><Typography className="text-base lg:text-xl">Sell Ordinals</Typography></button>
                                </div>
                            </div>
                            <div className="relative mt-[-60px] z-50">
                                <Card className={"z-10 -rotate-3 w-full h-full border border-ordinsea-fontYellow "+(!isNight?"bg-white":"bg-ordinsea-albumBackground")}>
                                    <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                        {collectionDatas[index] &&
                                            <img src={collectionDatas[index].collection_image} className="z-10 min-w-[350px] w-[400px] h-[400px]" alt="profile"/>
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div className="flex text-white">
                                            <Typography variant="paragraph">Otherdeed for Otherside</Typography>
                                            <Typography variant="paragraph" className="text-ordinsea-fontYellow">(come soon)</Typography>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className={"absolute z-20 top-0 left-0 w-full h-full border border-ordinsea-fontYellow "+(!isNight?"bg-white":"bg-ordinsea-albumBackground")}>
                                    <CardHeader floated={false} className="h-full border border-ordinsea-fontYellow bg-transparent shadow-none">
                                        {collectionDatas[index] &&
                                            <img src={collectionDatas[index].collection_image} className="z-10 min-w-[350px] w-[400px] h-[400px]" alt="profile"/>
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        <div className="flex items-center text-white">
                                            <Typography variant="paragraph">Otherdeed for Otherside</Typography>
                                            <img src={badge} alt="badge" className="pl-2 w-7 h-5 my-auto" />
                                            <Typography variant="paragraph" className="ml-auto text-ordinsea-fontYellow">(come soon)</Typography>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto p-3 lg:px-16">
                <div className="text-center">
                    <button className={"w-full lg:w-[35rem] px-5 py-12 mt-48 mb-24 "+(!isNight?"border border-2 border-black":"bg-gradient-to-b from-ordinsea-gradientTop via-ordinsea-gradientBottom to-ordinsea-gradientTop")} type="button" onClick={()=>{navigatePath('/collection')}}><Typography variant="h3" className="text-2xl lg:text-3xl">TRENDING COLLECTIONS</Typography></button>
                </div>

                {collectionDatas.map((item, i) => {
                    if(i%3===0){
                        return(
                            <div key={j++} className="flex flex-col lg:flex-row mt-5 justify-between items-center">
                                {collectionDatas[i] !== undefined ? (
                                    <div onClick={()=>{props.selectCollection(collectionDatas[i])}}>
                                        <CollectionIcon image={collectionDatas[i].collection_image} floorValue={collectionDatas[i].floor_price} volumeValue={collectionDatas[i].total_volume} label={collectionDatas[i].collection_name} />
                                    </div>
                                ):(
                                    <div className="w-full hidden lg:block"></div>
                                )}
                                {collectionDatas[i+1] !== undefined ? (
                                    <div onClick={()=>{props.selectCollection(collectionDatas[i+1])}}>
                                        <CollectionIcon image={collectionDatas[i+1].collection_image} floorValue={collectionDatas[i+1].floor_price} volumeValue={collectionDatas[i+1].total_volume} label={collectionDatas[i+1].collection_name} />
                                    </div>
                                ):(
                                    <div className="w-full hidden lg:block"></div>
                                )}
                                {collectionDatas[i+2] !== undefined ? (
                                    <div onClick={()=>{props.selectCollection(collectionDatas[i+2])}}>
                                        <CollectionIcon image={collectionDatas[i+2].collection_image} floorValue={collectionDatas[i+2].floor_price} volumeValue={collectionDatas[i+2].total_volume} label={collectionDatas[i+2].collection_name} />
                                    </div>
                                ):(
                                    <div className="w-full hidden lg:block"></div>
                                )}
                            </div>
                        )
                    }
                })}
            </div>    
            
            <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
                <div className="container mx-auto p-3 lg:px-16">
                    <div className="text-center">
                        <button className={"w-full lg:w-[35rem] px-5 py-12 mt-32 "+(!isNight?"border border-2 border-black":"bg-gradient-to-b from-ordinsea-gradientTop via-ordinsea-gradientBottom to-ordinsea-gradientTop")} type="button" onClick={()=>{navigatePath('/collection')}}><Typography variant="h3" className="text-2xl lg:text-3xl">FEATURED COLLECTIONS</Typography></button>
                    </div>
                </div>
            </div>
            
            <div className="container mt-24 mx-auto p-3 lg:px-16">
                <div className="h-full">
                    {collectionDatas.map((collectionData, i) => {
                        if(i%3===0){
                            return(
                                <div key={k++} className="flex flex-col lg:flex-row pt-5 px-5 lg:px-0 gap-10 justify-between items-center">
                                    {collectionDatas[i] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                    {collectionDatas[i+1] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i+1]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i+1].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="text-gray">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i+1].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                    {collectionDatas[i+2] !== undefined ? (
                                        <Card className={"w-full h-full border border-ordinsea-cardBackground cursor-pointer "+(!isNight?"bg-transparent":"bg-ordinsea-cardBackground")} onClick={()=>{props.selectCollection(collectionDatas[i+2]);navigatePath('/ordinals')}}>
                                            <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                                                <div className={"flex flex-row "+(!isNight?"text-black":"text-white")}>
                                                    <div className="flex flex-row">
                                                        <img src={NftAvatar} alt="profile" className="pr-3"/>
                                                        <div className="my-auto">
                                                            <Typography variant="lead" className=" ">
                                                                {collectionDatas[i+2].collection_name}
                                                            </Typography>
                                                            <Typography variant="small" className="text-gray">
                                                                {'@wzard'}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row ml-auto my-auto">
                                                        <img src={favoriteIcon} alt="favorite" className="w-5 h-4 pr-1 my-auto"/>
                                                        <Typography variant="small" className=" ">
                                                            {'92'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                <img src={collectionDatas[i+2].collection_image} alt="profile" className="w-full max-h-72 min-h-72" />
                                            </CardBody>
                                        </Card>
                                    ):(
                                        <div className="w-full hidden lg:block"></div>
                                    )}
                                </div>
                            )
                        }
                    })}    
                </div>
                <div className="text-center">
                    <button className={"w-full lg:w-[35rem] px-5 py-12 mt-32 "+(!isNight?"border border-2 border-black":"bg-gradient-to-b from-ordinsea-gradientTop via-ordinsea-gradientBottom to-ordinsea-gradientTop")} type="button"><Typography variant="h3" className="text-2xl lg:text-3xl">PARTNERS</Typography></button>
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center px-4 py-8 mt-24 border border-2 border-ordinsea-fontYellow">
                    <img src={partnerImage1} alt="partner_image" className="pb-3 lg:pb-0"/>
                    <img src={partnerImage2} alt="partner_image" className="pb-3 lg:pb-0"/>
                    <img src={partnerImage3} alt="partner_image" className="pb-3 lg:pb-0"/>
                    <img src={partnerImage4} alt="partner_image" className="pb-3 lg:pb-0"/>
                    <img src={partnerImage5} alt="partner_image" />
                </div>
            </div>

            <div className="relative bg-no-repeat bg-cover bg-center bg-pngimg1">
                <div className="absolute left-0 top-[87%] lg:top-[73%] ">
                    <img src={spline2Image} alt="spline1Image" className="w-full z-10"/>
                </div>
                <div className="container mx-auto p-3 lg:px-16 ">
                    <div className="relative text-center z-50">
                        <button className={"w-full lg:w-[35rem] px-5 py-12 mt-24 "+(!isNight?"border border-2 border-black":"bg-gradient-to-b from-ordinsea-gradientTop via-ordinsea-gradientBottom to-ordinsea-gradientTop")} type="button"><Typography variant="h3" className="text-2xl lg:text-3xl">WHY US</Typography></button>
                    </div>
                </div>
            </div>

            <div className="container mx-auto p-3 lg:px-16">
                <div className="flex flex-col lg:flex-row justify-between items-center p-4 mt-24">
                    <Card className="w-full h-full bg-transparent shadow-none z-50">
                        <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                            <img src={memberImage1} alt="profile" className="mx-auto"/>
                        </CardHeader>
                        <CardBody className={"text-center "+(!isNight?"text-black":"text-white")}>
                            <Typography variant="lead" className="pb-1">
                                Easy to use
                            </Typography>
                            <Typography variant="paragraph" className=" ">
                                Nice user friendly interface making it easy to trade bitcoin ordinals.
                            </Typography>
                        </CardBody>
                    </Card>
                    <Card className="w-full h-full bg-transparent shadow-none  z-50">
                        <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                            <img src={memberImage2} alt="profile" className="mx-auto"/>
                        </CardHeader>
                        <CardBody className={"text-center "+(!isNight?"text-black":"text-white")}>
                            <Typography variant="lead" className="pb-1">
                                Highly Secure
                            </Typography>
                            <Typography variant="paragraph" className=" ">
                                Ordinsea will use a very secure and trustless escrow system for ordinal trading.
                            </Typography>
                        </CardBody>
                    </Card>
                    <Card className="w-full h-full bg-transparent shadow-none  z-50">
                        <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                            <img src={memberImage3} alt="profile" className="mx-auto"/>
                        </CardHeader>
                        <CardBody className={"text-center "+(!isNight?"text-black":"text-white")}>
                            <Typography variant="lead" className="pb-1">
                                Experienced Team
                            </Typography>
                            <Typography variant="paragraph" className=" ">
                                Our team of developers have years of experience working on the Bitcoin Blockchain.
                            </Typography>
                        </CardBody>
                    </Card>
                    <Card className="w-full h-full bg-transparent shadow-none  z-50">
                        <CardHeader floated={false} className="h-full bg-transparent shadow-none">
                            <img src={memberImage4} alt="profile" className="mx-auto"/>
                        </CardHeader>
                        <CardBody className={"text-center "+(!isNight?"text-black":"text-white")}>
                            <Typography variant="lead" className="pb-1">
                                Empowering Creators
                            </Typography>
                            <Typography variant="paragraph" className=" ">
                                Ordinsea will allow creators to deploy their work on the blockchain with just a few clicks.
                            </Typography>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    </div>
    </React.Fragment>
}