import React from "react";
import { Link } from "react-router-dom";
import ImagesDatas from "../../../config/image";
import { Typography, MenuItem } from "@material-tailwind/react";
import useSharedState from './../../../hooks/useSharedState';
import NavListMenu from "./NavListMenu";
import { menuItems } from "../../../config/menu";
import Connect from "../../../components/Connect";

export default function NavListItem() {
  const {isNight, universalSearch, setUniversalSearch}=useSharedState();

  const handleChange = (e) => {
    if(e.target.value=="")
      setUniversalSearch(null);
    else
      setUniversalSearch(e.target.value);
  }

  const { 
    dollarIcon,
    dollarIcon_b, }=ImagesDatas();

  return (
    <React.Fragment>
      <input className="w-full rounded-full bg-blue-200/[.1] mt-5 p-3 text-[13px] lg:hidden" placeholder="Search1 Collections, NFT, Users..." value={universalSearch} onChange={handleChange}/>
      <ul className="my-2 flex flex-col gap-1 lg:my-0 lg:flex-row lg:items-center">
        {menuItems.map((menu, key) =>
          menu.link === undefined ? (
            <NavListMenu key={menu.label} menu={menu} />
          ) : (
            <Link key={menu.label} to={menu.link}>
              <MenuItem className="flex items-center gap-2 !bg-transparent lg:rounded-full">
                <Typography variant="paragraph" className={""+(!isNight?"text-black bg-white":"text-white")}>
                  {menu.label}
                </Typography>
              </MenuItem>
            </Link>
          )
        )}
      </ul>
      <div className="w-fit flex rounded-full border border-ordinsea-fontYellow px-5 py-3 bg-gradient-to-r from-ordinsea-gradientLeft to-ordinsea-gradientRight cursor-pointer lg:hidden">
        <img src={(!isNight?dollarIcon_b:dollarIcon)} className="max-h-8 pr-2" alt="day_icon" />
        <Typography variant="paragraph">Buy $OSEA</Typography>
      </div>
      <div className="text-left block lg:hidden">
        <Connect className="mt-4" />
      </div>
    </React.Fragment>
  );
}
