import React, {
    useState
} from "react";
import {
    useBetween
} from "use-between";

import IconImage1 from "../assets/img/icon/Nft/icon1.png";
import IconImage2 from "../assets/img/icon/Nft/icon2.png";
import IconImage3 from "../assets/img/icon/Nft/icon3.png";
import IconImage4 from "../assets/img/icon/Nft/icon4.png";
import IconImage5 from "../assets/img/icon/Nft/icon5.png";
import IconImage6 from "../assets/img/icon/Nft/icon6.png";
import IconImage7 from "../assets/img/icon/Nft/icon7.png";
import IconImage8 from "../assets/img/icon/Nft/icon8.png";
import IconImage10 from "../assets/img/icon/Nft/icon10.png";
import IconImage11 from "../assets/img/icon/Nft/icon11.png";
import IconImage12 from "../assets/img/icon/Nft/icon12.png";

import ActivityIcon1 from "../assets/img/icon/activity_icon/activity_1.png";
import ActivityIcon2 from "../assets/img/icon/activity_icon/activity_2.png";
import ActivityIcon3 from "../assets/img/icon/activity_icon/activity_3.png";
import ActivityIcon4 from "../assets/img/icon/activity_icon/activity_4.png";
import ActivityIcon5 from "../assets/img/icon/activity_icon/activity_5.png";
import ActivityIcon6 from "../assets/img/icon/activity_icon/activity_6.png";
import ActivityIcon7 from "../assets/img/icon/activity_icon/activity_7.png";
import ActivityIcon8 from "../assets/img/icon/activity_icon/activity_8.png";
import ActivityIcon9 from "../assets/img/icon/activity_icon/activity_9.png";

// Make a custom hook with your future shared state
const useFormState = () => {
    const Collections = [{
        collection_id: 1,
        collection_name: "DeGod",
        collection_image: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreigta67pp3zctiwk2lgctpnvm24qttcmei2jrzsqtkghghsr2huorq.ipfs.nftstorage.link/",
        collection_description: "Over 1 year ago. 535 DeGods were burned. Many have speculated on their return. These DeGods have been forever inscribed on Bitcoin, the blockchain where it all started. All on one single block. Block 776408.",
        ordinals_count: 3,
        owner_count: 3,
        floor_price: 0.01,
        total_volume: 0.029999999329447743
    }];

    const Ordinals = [{
            "ordinal_id": 1,
            "ordinal_name": "DeGods",
            "ordinal_number": 949,
            "collection_name": "BTC DeGod",
            "inscription_id": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
            "owner": "bc1prh0cd85fvtdqpte4anvu78ma49rffq5ljjm35a78wvxev6ynh58sxjvvts",
            "price": 0.01,
            "state": 1,
            "inscription": {
                "id": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
                "number": 77437,
                "address": "bc1papdslu9xmqrk98q4n4h3p5tnnm2ewcr92zqrgqnpkzmqrpm8k0wq76pavr",
                "genesis_address": "bc1papdslu9xmqrk98q4n4h3p5tnnm2ewcr92zqrgqnpkzmqrpm8k0wq76pavr",
                "genesis_block_height": 776408,
                "genesis_block_hash": "000000000000000000002067327ceda00d90cb546e3b5efbdf2b5fe484442813",
                "genesis_tx_id": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383",
                "genesis_fee": "1480",
                "genesis_timestamp": 1676327774000,
                "tx_id": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383",
                "location": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383:0:0",
                "output": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383:0",
                "value": "10000",
                "offset": "0",
                "sat_ordinal": "1180283366287977",
                "sat_rarity": "common",
                "sat_coinbase_height": 262113,
                "mime_type": "image/webp",
                "content_type": "image/webp",
                "content_length": 5352,
                "timestamp": 1676327774000
            },
            "sat": {
                "coinbase_height": 262113,
                "cycle": 0,
                "decimal": "262113.866287977",
                "degree": "0°52113′33″866287977‴",
                "inscription_id": "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
                "epoch": 1,
                "name": "fmjefsanuom",
                "offset": 866287977,
                "percentile": "56.20396988506139%",
                "period": 130,
                "rarity": "common"
            },
        },
        {
            "ordinal_id": 2,
            "ordinal_name": "DeGods",
            "ordinal_number": 7348,
            "collection_name": "BTC DeGod",
            "inscription_id": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446i0",
            "owner": "bc1px7lcxgu24atfdpdhtakyayaekta5xpsyarnh29safj4tg5fx43zqf3mp4w",
            "price": 0.02,
            "state": 1,
            "inscription": {
                "id": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446i0",
                "number": 77551,
                "address": "bc1pdz7mzudhl5mlgc2m5skj3u8cheedjwsgaekfjkkfz3vadajuxm0q7chpu3",
                "genesis_address": "bc1pdz7mzudhl5mlgc2m5skj3u8cheedjwsgaekfjkkfz3vadajuxm0q7chpu3",
                "genesis_block_height": 776408,
                "genesis_block_hash": "000000000000000000002067327ceda00d90cb546e3b5efbdf2b5fe484442813",
                "genesis_tx_id": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446",
                "genesis_fee": "1612",
                "genesis_timestamp": 1676327774000,
                "tx_id": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446",
                "location": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446:0:0",
                "output": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446:0",
                "value": "10000",
                "offset": "0",
                "sat_ordinal": "1922406405260660",
                "sat_rarity": "common",
                "sat_coinbase_height": 765850,
                "mime_type": "image/webp",
                "content_type": "image/webp",
                "content_length": 5876,
                "timestamp": 1676327774000
            },
            "sat": {
                "coinbase_height": 765850,
                "cycle": 0,
                "decimal": "765850.155260660",
                "degree": "0°135850′1786″155260660‴",
                "inscription_id": "23b2f6a41d0b330b8d19563ac24be8b9abb1e83a5a02188d73bc5d40f09d9446i0",
                "epoch": 3,
                "name": "afrkgfhongv",
                "offset": 155260660,
                "percentile": "91.54316225596705%",
                "period": 379,
                "rarity": "common"
            }
        },
        {
            "ordinal_id": 3,
            "ordinal_name": "DeGods",
            "ordinal_number": 880,
            "collection_name": "BTC DeGod",
            "inscription_id": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044i0",
            "owner": "bc1p3lqnckgx50v30jketl5wtlu27y25atha6zsg2vw55g3dec2ylrwq8tcnzg",
            "price": 0.03,
            "state": 1,
            "inscription": {
                "id": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044i0",
                "number": 77451,
                "address": "bc1pvmdvmu7lj89uayxh8w3yu09dlyhqjvzrqk8z93wypy7t0yrzgn0q57jz20",
                "genesis_address": "bc1pvmdvmu7lj89uayxh8w3yu09dlyhqjvzrqk8z93wypy7t0yrzgn0q57jz20",
                "genesis_block_height": 776408,
                "genesis_block_hash": "000000000000000000002067327ceda00d90cb546e3b5efbdf2b5fe484442813",
                "genesis_tx_id": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044",
                "genesis_fee": "1501",
                "genesis_timestamp": 1676327774000,
                "tx_id": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044",
                "location": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044:0:0",
                "output": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044:0",
                "value": "10000",
                "offset": "0",
                "sat_ordinal": "770896783409262",
                "sat_rarity": "common",
                "sat_coinbase_height": 154179,
                "mime_type": "image/webp",
                "content_type": "image/webp",
                "content_length": 5436,
                "timestamp": 1676327774000
            },
            "sat": {
                "coinbase_height": 154179,
                "cycle": 0,
                "decimal": "154179.1783409262",
                "degree": "0°154179′963″1783409262‴",
                "inscription_id": "d8ab4d43d0e5bfd8e2273591dc55d88b76c040bd60f2db8f3fce5c42df31a044i0",
                "epoch": 0,
                "name": "ijtowpfntdz",
                "offset": 1783409262,
                "percentile": "36.709370678916606%",
                "period": 76,
                "rarity": "common"
            }
        }
    ]

    const initalOrdinalDetail = {
        ordinal_id: 1,
        ordinal_name: "BTC DeGods",
        ordinal_number: 949,
        collection_name: "DeGod",
        inscription_id: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
        inscription_number: 77437,
        content_link: "https://ord-mirror.magiceden.dev/content/4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
        content_type: "image/webp",
        price: 0.01,
        state: 1,
        inscription: {
            id: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
            number: 77437,
            address: "bc1papdslu9xmqrk98q4n4h3p5tnnm2ewcr92zqrgqnpkzmqrpm8k0wq76pavr",
            genesis_address: "bc1papdslu9xmqrk98q4n4h3p5tnnm2ewcr92zqrgqnpkzmqrpm8k0wq76pavr",
            genesis_block_height: 776408,
            genesis_block_hash: "000000000000000000002067327ceda00d90cb546e3b5efbdf2b5fe484442813",
            genesis_tx_id: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383",
            genesis_fee: "1480",
            genesis_timestamp: 1676327774000,
            tx_id: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383",
            location: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383:0:0",
            output: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383:0",
            value: "10000",
            offset: "0",
            sat_ordinal: "1180283366287977",
            sat_rarity: "common",
            sat_coinbase_height: 262113,
            mime_type: "image/webp",
            content_type: "image/webp",
            content_length: 5352,
            timestamp: 1676327774000
        },
        sat: {
            coinbase_height: 262113,
            cycle: 0,
            decimal: "262113.866287977",
            degree: "0°52113′33″866287977‴",
            inscription_id: "4f88c517a52f83b9b0d733b4eb81979e82ca315aea5d863995fe7cff70c91383i0",
            epoch: 1,
            name: "fmjefsanuom",
            offset: 866287977,
            percentile: "56.20396988506139%",
            period: 130,
            rarity: "common"
        }
    }

    const initialOrdinalsOfWhitepaper = [{
        "ordinal_id": 4,
        "ordinal_name": "elixir-ovol",
        "ordinal_number": 230,
        "collection_name": "Bitcoin Ovals",
        "inscription_id": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74i0",
        "owner": "bc1p4tu29un4ef7d87yyquf3uw57gegfkhk5w85pm6csdf0vgfx40fgsc76r8w",
        "price": 0.04,
        "state": 1,
        "inscription": {
            "id": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74i0",
            "number": 635262,
            "address": "bc1p4tu29un4ef7d87yyquf3uw57gegfkhk5w85pm6csdf0vgfx40fgsc76r8w",
            "genesis_address": "bc1p4tu29un4ef7d87yyquf3uw57gegfkhk5w85pm6csdf0vgfx40fgsc76r8w",
            "genesis_block_height": 782961,
            "genesis_block_hash": "00000000000000000000888da44184be2b3cdceb0e33f8aaa01f241a2798b47e",
            "genesis_tx_id": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74",
            "genesis_fee": "21680",
            "genesis_timestamp": 1680053315000,
            "tx_id": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74",
            "location": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74:0:0",
            "output": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74:0",
            "value": "10000",
            "offset": "0",
            "sat_ordinal": "314241594044380",
            "sat_rarity": "common",
            "sat_coinbase_height": 62848,
            "mime_type": "image/png",
            "content_type": "image/png",
            "content_length": 8089,
            "timestamp": 1680053315000
        },
        "sat": {
            "coinbase_height": 62848,
            "cycle": 0,
            "decimal": "62848.1594044380",
            "degree": "0°62848′352″1594044380‴",
            "inscription_id": "914cda5ecec2bff607b3046be2dd849fe3e6a4fc453693557ebcd2c1be99fd74i0",
            "epoch": 0,
            "name": "lpwisaoapsn",
            "offset": 1594044380,
            "percentile": "14.963885447145042%",
            "period": 31,
            "rarity": "common"
        }
    }]

    const ActivityIcons = [{
            image: ActivityIcon1,
            label: 'Phasellus',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon2,
            label: 'Aenean sodales',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon3,
            label: 'Duis et purus',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon4,
            label: 'Nullam non',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon5,
            label: 'Vivamus',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon6,
            label: 'Etiam fermentum',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon7,
            label: 'Nam vitae',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon8,
            label: 'Proin pharetra',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        },
        {
            image: ActivityIcon9,
            label: 'Mauris hendrerit',
            id: '1245698',
            quality: 1,
            from: 'yatseen.eth',
            to: 'steddy.eth',
            time: '21 Hours Ago'
        }
    ]

    const [collectionDatas, setCollectionDatas] = React.useState(Collections);
    // const [collectionDatas, setCollectionDatas] = React.useState([]);
    const [ordinalDatas, setOrdinalDatas] = React.useState(Ordinals);
    // const [ordinalDatas, setOrdinalDatas] = React.useState([]);
    const [ordinalDetail, setOrdinalDetail] = React.useState(initalOrdinalDetail);
    // const [ordinalDetail, setOrdinalDetail] = React.useState('');
    const [ordinalsOfWhitepaper, setOrdinalsOfWhitepaper] = React.useState(initialOrdinalsOfWhitepaper);
    const [activityIcons, setActivityIcons] = React.useState(ActivityIcons);
    const [isNight, setIsNight] = React.useState(true);

    const [userWalletData, setUserWalletData] = React.useState(null);
    // universal search
    const [universalSearch, setUniversalSearch] = React.useState(null);

    return {
        // tableDatas, setTableDatas,
        collectionDatas,
        setCollectionDatas,
        ordinalDatas,
        setOrdinalDatas,
        ordinalDetail,
        setOrdinalDetail,
        ordinalsOfWhitepaper,
        setOrdinalsOfWhitepaper,
        activityIcons,
        setActivityIcons,
        userWalletData,
        setUserWalletData,
        isNight,
        setIsNight,
        universalSearch,
        setUniversalSearch
    };
};

const useSharedState = () => useBetween(useFormState);

export default useSharedState